//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	data(){
		return {
			value1: [new Date(2022, 2, 22, 8, 40), new Date(2022, 2, 22, 9, 40)],
			dialogImageUrl: '',
			dialogVisible: false,
			dialogImageUrl2: '',
			dialogVisible2: false,
			textarea:''
		}
	},
	methods:{
		time(){
			console.log(this.value1)
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		handleRemove2(file, fileList) {
			console.log(file, fileList);
		},
		handlePictureCardPreview2(file) {
			this.dialogImageUrl2 = file.url;
			this.dialogVisible2 = true;
		}
	}
}
